import { clearContainerOnce, simpleTemplate } from '.';
import { store } from '../../state/store';
import { render } from 'lit-html';

let autosuggestState;
store.subscribe(() => {
  const state = store.getState();

  if (state.autosuggest === autosuggestState) {
    return;
  }

  try {
    const container = document.querySelector('.header .search-results');
    if (container) {
      clearContainerOnce(container);
      render(simpleTemplate(state), container);
    }
  } catch (e) {
    console.error(e);
  }
});
