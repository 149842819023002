import '../features/autosuggest/simple';
import { defaultKeyUpDebounceMs } from '../util/ux';
import { isConstructorAutoSuggestEnabled } from '../features/autosuggest';
import { search } from '../features/autosuggest/state';
import { store } from '../state/store';
import debounce from 'lodash-es/debounce';
import { trackAutosuggestPopularClick, trackAutosuggestResultClick, trackAutosuggestSearch } from '../features/autosuggest/tracking';

// Expose some functions to the global namespace for use in
// `../mv2020/header.js`.
window.mv = window.mv || {};
window.mv.header = window.mv.header || {};
window.mv.header.isConstructorAutoSuggestEnabled = isConstructorAutoSuggestEnabled;
window.mv.header.autosuggest = debounce((query, simple) => store.dispatch(search({ query, simple })), defaultKeyUpDebounceMs);
window.mv.header.trackAutosuggestPopularClick = trackAutosuggestPopularClick;
window.mv.header.trackAutosuggestResultClick = trackAutosuggestResultClick;
window.mv.header.trackAutosuggestSearch = trackAutosuggestSearch;
